export const appHead = {"meta":[{"charset":"utf-8"},{"name":"robots","content":"noindex,nofollow"},{"name":"viewport","content":"width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"}],"link":[],"style":[],"script":[{"src":"/js/channelTalk.js","async":true,"defer":true},{"children":"\n            (function (w, h, _a, t, a, b) {\n              w = w[a] = w[a] || {\n                config: {\n                  projectAccessKey: \"x60549g85q00u-x38rod7nu89rm9-z69ure29rb6tr3\",\n                  pcode: 42060,\n                  sampleRate: 10,\n                  proxyBaseUrl: \"https://rum-ap-northeast-2.whatap-browser-agent.io/\",\n                },\n              };\n              a = h.createElement(_a);\n              a.async = 1;\n              a.src = t;\n              t = h.getElementsByTagName(_a)[0];\n              t.parentNode.insertBefore(a, t);\n            })(window, document, 'script', 'https://repo.whatap-browser-agent.io/rum/prod/v1/whatap-browser-agent.js', 'WhatapBrowserAgent', '');\n            "}],"noscript":[],"charset":"utf-8","title":"자작자작"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000